body{
    background: linear-gradient(45deg, #ff9a9e, #fad0c4, #fad0c4, #a18cd1, #fbc2eb);
}
.backgroundimage {
    width: 100%;
    height: auto;
    padding: 1rem 0 30px 0;
}
.devilwing {
    position: absolute;
    top: 10px;
    left: 0;
    width: 26%;
}
p{
    font-size: 1.1rem;
}
h2,h3{
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif !important;
}
.angelwind {
    position: absolute;
    top: 10px;
    right: 0;
    width: 25%;
}
.banner-H {
    font-family: "Hurricane", cursive !important;
    font-weight: bold;
    color: #232323;
    font-size: 4.5rem; 
    text-align: center;
}
.banner-P {
    font-size: 1rem; 
    font-weight: 500 !important;
    color: #232323;
    text-align: center;
    margin: 0;
}
.css-l21kz9{
    width: 43% !important;
}
@media screen and (max-width: 768px) {
    .backgroundimage {
        /* padding: 30px 0; */
    }
    .banner-H {
        font-size: 2.5rem; 
    }
    p{
        font-size: 1rem;
    }
    h2,h3{
        font-size: 1.2rem;
        font-weight: 600;
        font-family: "Montserrat", sans-serif !important;
    }
    h4{
        font-size: 1.2rem !important;
        color: #000 !important;
    }
    .startbtn{
        width: 180px !important;
        padding: 10px;
        height: auto !important;
        color: #000;
        background-color: #f8ec0c;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.1rem !important;
        margin-top: 1rem;
    }
    .list{
        font-weight: 500 !important;
    }
    
    .banner-P {
        font-size: 0.9rem; 
        padding-bottom: 0.7rem;
    }
    .bannertext{
        width: 90% !important;
        padding-top: 1rem;
    }
   
    .inputbox{
        width: 175px !important;
        margin-left: 0 !important;
        font-weight: bold !important;
        text-align: center !important;
    }
    .userName{
        width: 175px !important;
        margin-left: 0 !important;
    }
    .yesbtn{
        padding: 3px 30px !important;
        border-radius: 10px;
        border: none;
        font-size: 1rem !important;
        font-weight: bold;
        margin-right: 0.8rem !important;
        background-color: #fff !important;
    }
    .nobtn{
        padding: 3px 30px !important;
        border-radius: 10px;
        border: none;
        font-size: 1rem !important;
        font-weight: bold;
        margin-left: 0.8rem !important;
        background-color: #fff !important;
    }
    .bottomBox{
        font-size: 1.2rem !important;
        font-weight: 600;
        text-align: center;
    }
    .back{
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1rem !important;
        font-weight: 600;
    }
    .css-l21kz9{
        width: 60% !important;
    }
    .warning{
        width: 80% !important;
        font-size: 10px !important;
    }
    .sticky{
        font-size: 1.5rem !important;
        background-color: white;
        z-index: 100;
        font-weight: 600;
        position: fixed;
        left: 50%;
        top: 50px;
        transform: translateX(-50%) translateY(-50%);
        padding: 0.5rem 1rem;
        border-radius: 10px;
    }
}
.inputbox{
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-weight: bold !important;
    text-align: center !important;
}
::placeholder {
    color: #000;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}
#lang{
    border: none;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1rem;
}
.startbtn{
    /* padding: 6px 2rem; */
    /* width: 170px; */
    color: #000;
    padding: 10px 15px;
    background-color: #f8ec0c;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1rem;
}
.startbtn:hover{
    box-shadow: -1px 2px 12px 2px rgba(0,0,0,0.75);
    transition: 1s;
}
.progress-bar{
    background-color: #A0DC65;
    color: #000;
}
.bottomBox{
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    margin-top: 1rem;
}
.yesbtn{
    padding: 3px 55px;
    border-radius: 50px;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    margin-right: 1.5rem;
    background-color: #fff !important;
}
.nobtn{
    padding: 3px 55px;
    border-radius: 50px;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 1.5rem ;
    background-color: #fff !important;
}
.back{
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
    padding-bottom: 1.5rem;
}
.whitebox{
    background-color: rgba(255,255,255,0.5);
    border-radius: 15px;
}
.warning{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    margin-top: 3rem !important;
}
.userName{
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    margin-left: 7rem;
    background-color: #fff;
    text-align: center;
    font-weight: bold;
}
.calculatebtn{
    background-color: #3E3E3E !important;
    color: #fff !important;
    padding: 12px 50px !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
    border: none !important;
}

@media screen and (max-width: 400px) {
    .startbtn{
        font-size: 0.8rem;
    }
    .calculatebtn{
        font-size: 0.8rem !important;
        padding: 8px 10px!important;
    }
 
}
.heartimg{
    width: 130px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.result{
    width: 100%;
    height: auto;
    background: linear-gradient(45deg, #ff9a9e, #fad0c4, #fad0c4, #a18cd1, #fbc2eb);
    border-radius: 10px !important;
    padding: 1rem;
    overflow-y: auto;
}
.css-gcr8ku{
    padding: 0 !important;
    border: none !important;
    border-radius: 10px !important;
}
.score-H{
    font-size: 1.8rem !important;
    padding-top: 1rem;
}
.score-P{
    font-size: 1rem;
    font-weight: 500;
    padding-top: 1rem;
}
.css-l21kz9{
    padding: 0 !important;
    border: none !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}
.restartbtn{
    background-color: #000 !important;
    color: #fff !important;
    padding: 12px 50px !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
    border: none !important;
}
.scoreCount{
  color: #fff ;
  font-weight: 600 !important;
}
table {
    border-collapse: collapse;
    width: 100%;
}
td, th {
    border: 1px solid #000;
    text-align: left;
    padding: 8px;
    text-align: center;
}
.highlight{
    background-color: #fff !important;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    box-shadow: 1px 1px 5px #fff;
}
.modal-body{
    padding: 0px !important;
}
  /* Style individual table cells */
td {
    padding: 10px;
}
.box-White{
    background-color: #fff;
    padding: 0.5rem;
    height: 100%;
    border-radius: 10px;
}
.points{
    font-weight: 600;
}
.highlight{
    box-shadow: 0px 3px 10px 0px #000;
    padding: 0.5rem;
    background-color: #fff;
}
#imageShare{
    display: none !important;
}
.socialmedia{
    width: 7% !important;
}
@media (min-width:768px){
    .socialmedia{
        width: 3% !important;
    }
    .bannertext{
        width: 45% !important;
    }
}
.boxshadow{
    -webkit-box-shadow: -1px 1px 18px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 1px 18px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 1px 18px 0px rgba(0,0,0,0.75);
}
.accordion-button:not(.collapsed){
    background-color: #fff !important;
    box-shadow: none !important;
}
.accordion-button{
    box-shadow: none !important;
}
.accordion-button:focus{
    border-color: #fff !important;
}
.list{
    list-style-type: none;
    cursor: pointer;
    font-weight: bolder;
}
.ULlist{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
h4{
    font-size: 1.3rem;
    color: #000 !important;
}
.sticky{
    font-size: 2rem;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 1px 1px 20px -2px rgba(255,255,255,0.7);
    z-index: 100;
    font-weight: 600;
    position: fixed;
    left: 50%;
    top: 50px;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 10px;
    padding: 0.5rem 1rem;
}
