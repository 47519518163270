.li-Priv{
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    padding-left: 2rem;
}
.li-Priv::before {
    content: '\2022';
    color: #000;
    margin-right: 8px;
    margin-top: 2px;
}